/**
 * Copyright 2021 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

// TODO: Show regions on a map, with lines overlayed according to ping times.
// TODO: Add an option to contribute times and JS geolocation info to a public BigQuery dataset.

import { MDCDialog } from "@material/dialog";
import { MDCDataTable } from "@material/data-table";
import { MDCTooltip } from "@material/tooltip";

const GLOBAL_REGION_KEY = "global";
const PING_TEST_RUNNING_STATUS = "running";
const PING_TEST_STOPPED_STATUS = "stopped";
const INITIAL_ITERATIONS = 10;
const btnCtrl = document.getElementById("stopstart");

/**
 * The `regions` obj is of the following format:
 * {
 *  "us-east1": {
 *    "key": "",
 *    "label": "",
 *    "pingUrl": "",
 *    "latencies": [],
 *    "median": ""
 *  }
 * }
 */
const regions = {};
const results = []; // this will always be sorted according to sortKey and sortDir
let pingTestStatus = PING_TEST_RUNNING_STATUS;
let fastestRegionVisible = false;
let fastestRegion = null;
let globalRegionProxy = "";
let sortKey = "median"; // column to sort the data with
let sortDir = "ascending"; // sorting direction(ascending/descending)

/**
 * Fetches the endpoints for different Cloud Run regions.
 * We will later send a request to these endpoints and measure the latency.
 */
function getEndpoints() {
  endpoints = {
    "us-west2": {
      Region: "35.236.77.102",
      RegionName: "Los Angeles",
      URL: "https://us-west2-5tkroniexa-wl.a.run.app",
      PcoipExt : "pcoip://35.236.77.102?data=eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsInByb3BYIjo0NTd9.eyJmdWxsc2NyZWVuIjp0cnVlLCJoYXJkLWhvc3QiOiIzNS4yMzYuMTExLjc3In0.DwOlEy2SfFnTtd7-jITBV_4VuASIl7tCSWKotrG7I7jphrpS64zkbhEUfXTvBAQ08mLFGSrQeAub20yoFog2474KrfaH7-6DHkMX9Xb0BV6-6W7otcbZo21OhcschIjZpqs72GLRAnuDxKM64RbS1HhJMyvtwW0xmRzXtywsyrLZzXcs4RBMOe854r6RtYfjy6I_ZZRWTUPTXrxmVNj4ScZLyNM9p_36qv4vNCgBm4EJmnMAF6WoJA7B_Ox-VHPHQ4Uknupy5oIVsjXJH_NnxnViKGtqzsipiL-wZOJenRpXenjKfDV3AfsDmtVK_LPLMfXXZsn2ruDAKxXJhrNLFA",
      PcoipInt : "pcoip://10.0.232.39?data=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmdWxsc2NyZWVuIjp0cnVlLCJoYXJkLWhvc3QiOiIxMC4wLjIzMi4zMyJ9.2h7KFOGlNe--rede99U8bj8nP00qbxoyMGWolf10nTg"
    },
    "europe-west8": {
      Region: "34.154.157.100",
      RegionName: "Milan",
      URL: "https://europe-west8-5tkroniexa-oc.a.run.app",
      PcoipExt : "pcoip://34.154.157.100?data=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmdWxsc2NyZWVuIjp0cnVlLCJoYXJkLWhvc3QiOiIzNC4xNTQuMTkwLjE5OSJ9.3D1SkBe8xyRY19nhGUxM0Yx_MzpuTA5YbgS6R7aWiAA",
      PcoipInt : ""
    },
    "us-east4": {
      Region: "34.48.116.142",
      RegionName: "North Virginia",
      URL: "https://us-east4-5tkroniexa-uk.a.run.app",
      PcoipExt : "pcoip://34.48.116.142?data=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmdWxsc2NyZWVuIjp0cnVlLCJoYXJkLWhvc3QiOiIzNC44NS4xOTYuMjEyIn0.zsKJRSUZK_gVQIHYEnMnA6HN2ac1KFYPCcPNiJ_xYzs",
      PcoipInt : "pcoip://10.0.232.39?data=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmdWxsc2NyZWVuIjp0cnVlLCJoYXJkLWhvc3QiOiIxMC4wLjIyNC4xMiJ9.RajywuGQfxJWsuRV0gtb7o0wL59U6TgBCJyPJZtPft8"
    },
    "us-central1": {
      Region: "35.194.5.45",
      RegionName: "Iowa",
      URL: "https://us-central1-5tkroniexa-uc.a.run.app",
      PcoipExt : "pcoip://35.194.5.45?data=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmdWxsc2NyZWVuIjp0cnVlLCJoYXJkLWhvc3QiOiIzNS4xOTQuNS40NSJ9.iQ-KkcrG8Jo-j_wCrJUI8Ll2gxCMdJhQ2m9mijQaKo0",
      PcoipInt : ""
    },
    "southamerica-east1": {
      Region: "35.247.235.60",
      RegionName: "São Paulo",
      URL: "https://southamerica-east1-5tkroniexa-rj.a.run.app",
      PcoipExt : "pcoip://35.247.235.60?data=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmdWxsc2NyZWVuIjp0cnVlLCJoYXJkLWhvc3QiOiIzNS4yNDcuMjA2LjQ3In0.yTOTvr8Yq5vFa8L53zkLK-W3R1Gd5K5MAPic-1lGylA",
      PcoipInt : ""
    },
    "northamerica-northeast1": {
      Region: "35.234.247.248",
      RegionName: "Montréal",
      URL: "https://northamerica-northeast1-5tkroniexa-nn.a.run.app",
      PcoipExt : "pcoip://35.234.247.248?data=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmdWxsc2NyZWVuIjp0cnVlLCJoYXJkLWhvc3QiOiIzNS4yMDMuMTI1LjQwIn0.Ng8zp8DlYOoLpGSIaAMAtGUvto9-2_Xd_s7m6X4-rGI",
      PcoipInt : ""
    },
    "europe-west2": {
      Region: "35.246.30.92",
      RegionName: "London",
      URL: "https://europe-west2-5tkroniexa-nw.a.run.app",
      PcoipExt : "pcoip://35.246.30.92?data=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmdWxsc2NyZWVuIjp0cnVlLCJoYXJkLWhvc3QiOiIzNC4xMDUuMTcxLjQxIn0.C0gIrM2KAeSr_7pXAZZ9usSkFGeYCnH2I8dowL1VvFA",
      PcoipInt : ""
    },
    "us-east1-c": {
      Region: "34.148.120.82",
      RegionName: "South Carolina",
      URL: "https://us-east1-5tkroniexa-ue.a.run.app",
      PcoipExt : "pcoip://34.148.120.82?data=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmdWxsc2NyZWVuIjp0cnVlLCJoYXJkLWhvc3QiOiIzNS4yMzEuMTExLjE0NiJ9.aLk0oYLidxqFs9l3wKljIWDG3ffnk3noLoFV9OVYw3o",
      PcoipInt : ""
    },
  };

  for (const zone of Object.values(endpoints)) {
    const gcpZone = {
      key: zone.Region,
      label: zone.RegionName,
      pingUrl: zone.URL + "/api/ping",
      latencies: [],
      median: "",
      PcoipExt: zone.PcoipExt,
      PcoipInt: zone.PcoipInt
    };
    // console.log(gcpZone.key);
    console.log("calling region here");
    console.log(regions);
    regions[gcpZone.key] = gcpZone;
    console.log("then here");
    console.log(regions);
  }

  // Once we're done processing the local endpoints variable, let's start pinging
  pingAllRegions(INITIAL_ITERATIONS);
}

/**
 * Ping all regions to fetch their latency
 *
 * @param {number} iter
 */
async function pingAllRegions(iter) {
  const regionsArr = Object.values(regions);

  for (let i = 0; i < iter; i++) {
    for (const region of regionsArr) {
      // Takes care of the stopped button
      if (pingTestStatus === PING_TEST_STOPPED_STATUS) {
        break;
      }

      const latency = await pingSingleRegion(region.key);

      // add the latency to the array of latencies
      // from where we can compute the median and populate the table
      regions[region.key]["latencies"].push(latency);
      regions[region.key]["median"] = getMedian(
        regions[region.key]["latencies"],
      );

      // update fastest region
      if (
        fastestRegion === null ||
        regions[region.key]["median"] < regions[fastestRegion]["median"]
      ) {
        fastestRegion = region.key;
      }

      addResult(region.key);
      updateList();
    }

    // start displaying the fastest region after at least 1 iteration is over.
    // subsequent calls to this won't change anything
    displayFastest(true);
  }

  // when all the region latencies have been fetched, let's update our status flag
  updatePingTestState(PING_TEST_STOPPED_STATUS);
}

/**
 * Computes the ping time for a single GCP region
 * @param {string} regionKey The key of the GCP region, ex: us-east1
 * @return {Promise} Promise
 */
function pingSingleRegion(regionKey) {
  return new Promise((resolve) => {
    const gcpZone = regions[regionKey];
    const start = new Date().getTime();

    fetch(gcpZone.pingUrl, {
      cache: "no-cache",
    }).then(async (resp) => {
      const latency = new Date().getTime() - start;

      // if we just pinged the global region, the response should contain
      // the region that the Global Load Balancer uses to route the traffic.
      if (regionKey === GLOBAL_REGION_KEY) {
        resp.text().then((val) => {
          globalRegionProxy = val.trim();
        });
      }

      resolve(latency);
    });
  });
}

/**
 * Function to update the current status of pinging
 * @param {string} status
 */
function updatePingTestState(status) {
  pingTestStatus = status;
  if (status === PING_TEST_RUNNING_STATUS) {
    btnCtrl.classList.add("running");
  } else if (status === PING_TEST_STOPPED_STATUS) {
    btnCtrl.classList.remove("running");
  }
}

/**
 * Updates the list view with the result set of regions and their latencies.
 */
function updateList() {
  let html = "";
  let cls = "";
  let regionKey = "";

  for (let i = 0; i < results.length; i++) {
    cls =
      results[i] === fastestRegion && fastestRegionVisible
        ? "fastest-region"
        : "";
    regionKey = getDisplayedRegionKey(results[i]);
    html +=
      '<tr class="mdc-data-table__row ' +
      cls +
      '"><td class="mdc-data-table__cell regiondesc">' +
      regions[results[i]]["label"] +
      '<div class="embedded-region d-none d-md-block">' +
      regionKey +
      "</div>" +
      '</td><td class="mdc-data-table__cell region d-md-none">' +
      regionKey +
      "</td>" +
      '<td class="mdc-data-table__cell result"><div>' +
      regions[results[i]]["median"] +
      ' ms</div></td>' +
      '<td class="mdc-data-table__cell pcoipext"><a href="' +
      regions[results[i]]["PcoipExt"] +
      '" target="_blank"><button class="mdc-button mdc-button--raised"><span class="mdc-button__label">Connect</span></button></a></td>' +
      '<td class="mdc-data-table__cell pcoipint"><a href="' +
      regions[results[i]]["PcoipInt"] +
      '" target="_blank"><button class="mdc-button mdc-button--raised"><span class="mdc-button__label">Connect</span></button></a></td></tr>';
  }

  document.getElementsByTagName("tbody")[0].innerHTML = html;
}



/**
 * Helper function to return median from a given array
 * @param {*} arr Array of latencies
 * @return {*}
 */
function getMedian(arr) {
  if (arr.length == 0) {
    return 0;
  }
  const copy = arr.slice(0);
  copy.sort();
  return copy[Math.floor(copy.length / 2)];
}

/**
 * Helper that adds the regionKey to it's proper position keeping the results array sorted
 * This means we don't always have to sort the whole results array
 * TODO: Try and use an ordered map here to simply this
 * @param {string} regionKey
 */
function addResult(regionKey) {
  if (!results.length) {
    results.push(regionKey);
    return;
  }

  // remove any current values with the same regionKey
  for (let i = 0; i < results.length; i++) {
    if (results[i] === regionKey) {
      results.splice(i, 1);
      break;
    }
  }

  // TODO: Probably use Binary search here to merge the following 2 blocks
  // if new region is at 0th position
  if (compareTwoRegions(regionKey, results[0]) < 0) {
    results.unshift(regionKey);
    return;
  }
  // if new region is at last position
  else if (compareTwoRegions(regionKey, results[results.length - 1]) > 0) {
    results.push(regionKey);
    return;
  }

  // add the region to it's proper position
  for (let i = 0; i < results.length - 1; i++) {
    // if the region to be added is b/w i and i+1 elements
    if (
      compareTwoRegions(regionKey, results[i]) >= 0 &&
      compareTwoRegions(regionKey, results[i + 1]) < 0
    ) {
      results.splice(i + 1, 0, regionKey);
      return;
    }
  }
}

/**
 * Sets the visiblity for the fastest region indicator on the list(the green cell)
 * @param {bool} isVisible Indicator to toggle visibility for the fastest region indicator
 */
function displayFastest(isVisible) {
  fastestRegionVisible = true;
  updateList();
}

/**
 * Helper function to deduce the region to be displayed in the list
 * @param {string} regionKey
 * @return {string}
 */
function getDisplayedRegionKey(regionKey) {
  // if the region is not global, return it as it is.
  if (regionKey !== GLOBAL_REGION_KEY) return regionKey;

  // if the region is global and we have received the region that is used by the Gloabl Load Balancer
  // we display that
  if (globalRegionProxy.length > 0)
    return "<em>→" + globalRegionProxy + "</em>";

  // if the region is global and we don't have the routing region, we show "gloabl"
  return "global";
}

/**
 * Sort the table data based on a column(defined in sortKey) and direction(sortDir)
 */
function sortResults() {
  results.sort(compareTwoRegions);
}

/**
 * Function to compare order of 2 regions based on the current sort options
 * @param {string} a Region key for first region to be compared
 * @param {string} b Region key for second region to be compared
 * @return {int}
 */
function compareTwoRegions(a, b) {
  const multiplier = sortDir === "ascending" ? 1 : -1;

  a = regions[a][sortKey];
  b = regions[b][sortKey];

  if (a == b) {
    return 0;
  }

  return multiplier * (a > b ? 1 : -1);
}

/**
 * Event listener for the button to start/stop the pinging
 */
btnCtrl.addEventListener("click", function () {
  const newStatus =
    pingTestStatus === PING_TEST_STOPPED_STATUS
      ? PING_TEST_RUNNING_STATUS
      : PING_TEST_STOPPED_STATUS;
  updatePingTestState(newStatus);

  if (newStatus === PING_TEST_RUNNING_STATUS) pingAllRegions(1);
});

// start the process by fetching the endpoints
getEndpoints();

window.onload = function () {
  // How it works btn
  const dialog = new MDCDialog(document.querySelector(".mdc-dialog"));
  document
    .querySelector(".how-it-works-link")
    .addEventListener("click", function (e) {
      e.preventDefault();
      dialog.open();
    });

  // init data-table
  new MDCDataTable(document.querySelector(".mdc-data-table"));

  document
    .querySelector(".mdc-data-table")
    .addEventListener("MDCDataTable:sorted", function (data) {
      const detail = data.detail;

      // update the sorting options according to the requested values
      (sortKey = detail.columnId), (sortDir = detail.sortValue);

      sortResults();
      updateList();
    });

  // init tooltips
  [].map.call(document.querySelectorAll(".mdc-tooltip"), function (el) {
    return new MDCTooltip(el);
  });
};
